
@font-face {
    font-family: "LG Smart UI";
    font-style: normal;
    font-weight: 300;
    src: url("styles/fonts/LG_Smart_UI-Light.woff") format("woff");
}
@font-face {
    font-family: "LG Smart UI";
    font-style: normal;
    font-weight: 400;
    src: url("styles/fonts/LG_Smart_UI-Regular.woff") format("woff");
}
@font-face {
    font-family: "LG Smart UI";
    font-style: normal;
    font-weight: 600;
    src: url("styles/fonts/LG_Smart_UI-SemiBold.woff") format("woff");
}
@font-face {
    font-family: "LG Smart UI";
    font-style: normal;
    font-weight: 700;
    src: url("styles/fonts/LG_Smart_UI-Bold.woff") format("woff");
}

:root{
    --bg-color: #F0ECE4;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -ms-content-zooming: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: "LG Smart UI", sans-serif;
    font-size: 10px;
    background-color: var(--bg-color);
    color: #fff;
}

button{
    font-family: "LG Smart UI", sans-serif;
}

a, a:hover, a:active, a:visited {
    text-decoration: none;
    color: inherit;
}

#root-container
{
    margin: 0;
    padding: 2rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-color);
}

#root-container-inner{
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    background: var(--bg-color);
    border: 1px solid #d0d0d0;
    filter: drop-shadow(0 0 0.75rem rgba(100, 100, 100, 0.4));
}

#pano, #root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    touch-action: none;
}

footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 999;
    pointer-events: none;
    padding: 2rem;
}

.copyright
{
    color: rgba(255,255,255,0.5);
    font-size: 1.4rem;
}

.hidden{
    visibility: hidden;
    display: inline;
}

.interface {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -ms-content-zooming: none;
}

::-webkit-scrollbar {
    width: 8px; /* 세로축 스크롤바 길이 */
}

::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #aaa;
    /*opacity: 30%;*/
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #eee;
    /*opacity: 30%;*/
}



@media screen and (max-width: 1280px), screen and (max-height: 768px){
    html, body {
        font-size: 8px;
    }


    ::-webkit-scrollbar {
        width: 6px; /* 세로축 스크롤바 길이 */
    }

    ::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #aaa;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #eee;
    }
}

@media screen and (max-width: 747px), screen and (max-height: 414px) {
    .copyright
    {
        visibility: hidden;
    }

    #root-container
    {
        padding: 1rem;
        /*height: 600px;*/
    }
}


#testModel{
    height: 100% !important;
}
